var generic = generic || {};

(function ($) {
  var invStatus;
  $(document).on('click', 'a.js-notify-me', function (e) {
    e.preventDefault();
    var skuBaseId = $(this).attr('data-sku-base-id');
    var skuInvStatus = $(this).attr('data-sku-inv-status');

    invStatus = [2, 7].includes(parseInt(skuInvStatus, 10)) ? 'BIS' : 'CS';
    var file = '/waitlist/signup.tmpl?SKU_BASE_ID=' + skuBaseId + '&REQUEST_TYPE=' + invStatus;
    var options = {
      includeBackground: false,
      includeCloseLink: true,
      hideOnClickOutside: true,
      cssStyle: {
        border: '1px solid #5dba98',
        width: '500px',
        height: '300px',
        padding: '0'
      }
    };

    options.content =
      '<iframe  class="waitlist-iframe-wrapper" frameborder="0" src="' + file + '"></iframe>';
    generic.overlay.launch(options);
  });

  // Update notify button base id when sku changes.
  $(document).on('product.skuSelect', '.js-product-ui:not(.js-product-bundle-ui)', function (e, skuBaseId) {
    var $product = $(this);
    var $notifyBtn = $('.js-notify-me', $product).filter(function () {

      return $(this).closest('.js-pdp-bundle-item').length < 1;
    });
    var $parent = $notifyBtn.closest('.js-product-full-notify-me, .js-product-brief-notify-me');
    var notifyMeFlag = 139;
    var skuData = prodcat.data.getSku(skuBaseId);
    var invStatus = 1;
    var classSelector = '';

    // Update sku base id on button.
    $notifyBtn.attr('data-sku-base-id', skuBaseId);
    if (skuData && skuData.INVENTORY_STATUS) {
      invStatus = skuData.INVENTORY_STATUS;
      $notifyBtn.attr('data-sku-inv-status', invStatus);
      classSelector = 'js-notify_me_inventory_status--' + invStatus;

      if (invStatus === 7 && skuData.MISC_FLAG !== notifyMeFlag) {
        classSelector = null;
      }
      if (!classSelector || !$parent.hasClass(classSelector)) {
        $parent.addClass('hidden');
      }
    }

    var $notifySoldOutBtn = $('.js-notify-display--soldout', $product);

    if ($notifySoldOutBtn.length > 0 && !!skuData) {
      $notifySoldOutBtn.removeClass('notify-display--soldout-' + notifyMeFlag);
      if (skuData.MISC_FLAG === notifyMeFlag) {
        $notifySoldOutBtn.addClass('notify-display--soldout-' + notifyMeFlag);
      }
    }
  });
})(jQuery);
